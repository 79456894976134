import React from 'react';
import axios from 'axios';

const privateJSON = require('../private.json');

const CreateUserButton = () => {
  const createUser = async () => {
    console.log(`Bearer ${privateJSON.neurelo_api_key}`)
    try {
        const response = await axios.get(`${privateJSON.relative_url}/rest/users`, {
          headers: {
            'Authorization': `Bearer ${privateJSON.neurelo_api_key}`
          }
        });
    
        if (response.status === 200) {
          console.log('API key works! Response data:', response.data);
        } else {
          console.log('Failed with status:', response.status);
        }
      } catch (error) {
        console.error('Error testing API key:', error.response ? error.response.data : error.message);
      }
  };

  return (
    <button onClick={createUser}>
      Create User
    </button>
  );
};

export default CreateUserButton;
